import { useEffect, useState, useMemo } from 'react';
import './App.css';
import { RevealOnScroll } from './RevealOnScroll'
import { ParticipanList } from './ParticipantList';
import { Invitation } from './Invitation';

function App() {
  const [participants, setParticipants] = useState(undefined);
  const [selectedParticipant, setSelectedParticipants] = useState(undefined);

  useEffect(() => {
    fetch('participants.json', {
      method: 'GET',
    })
      .then(r => r.json())
      .then(json => setParticipants(json))
      .catch(err => console.error(err));
  } , [setParticipants]);

  return (
    <div className="text-center h-svh container mx-auto snap-mandatory snap-y overflow-y-auto scroll-smooth text-gray-950">
      <div className='h-svh items-center justify-around snap-start snap-always grid grid-cols-4'>
          <div className='col-span-4 md:col-start-2 md:col-span-2 row-span-2 row-start-1'>
            <RevealOnScroll extraClasses={'delay-100 flex items-center justify-center'}>
              <img src='./valde.png' alt='' className='max-w-3/4 maxh50 '></img>
            </RevealOnScroll>
          </div>
          <RevealOnScroll extraClasses={'delay-200 flex justify-end'}>
            <img src='./cloud.png' alt='' className='maxh20'></img>
          </RevealOnScroll>
          <RevealOnScroll extraClasses={'delay-300 animate-[up_3s_ease-in-out_infinite] px-1 md:row-start-2 md:col-start-4'}>
            <img src='./balloon.png' alt='' className='maxh20'></img>
          </RevealOnScroll>
          <div className='col-span-2 row-start-3 col-start-2 space-y-6 flex flex-col justify-center items-center md:row-start-3'>
            <RevealOnScroll extraClasses={'delay-500 flex flex-col justify-center items-center'}>
              <h1 className='text-gray-950 font-serif text-3xl md:text-5xl lg:text-7xl tracking-widest'>VALDES</h1>
              <h1 className='text-gray-950 font-serif text-3xl md:text-5xl lg:text-7xl tracking-widest'>BARNEDÅB</h1>
              <div className='py-4 border-b-2 border-black w-28 md:w-56'></div>
            </RevealOnScroll>
            <RevealOnScroll extraClasses={'delay-700'}>
              <h1 className='text-gray-950 italic font-serif text-xs md:text-2xl lg:text-4xl font-extralight'>Lørdag den 16 marts kl 10:30</h1>
            </RevealOnScroll>
          </div>
          <RevealOnScroll extraClasses={'delay-500 animate-[up_3s_ease-in-out_infinite] md:col-start-1 md:row-start-3 flex justify-end'}>
            <img src='./balloon2.png' alt='' className='maxh20'></img>
          </RevealOnScroll>
          <RevealOnScroll extraClasses={'flex items-center justify-center delay-1000 col-span-2 md:row-start-4 md:col-start-2'}>
            <div className='md:text-3xl md:h-48 flex items-center justify-center md:space-x-2 space-x-1'>
              <p className='font-serif text-sm md:text-3xl'>Rul ned for invitation</p>
              <div className='animate-[upl_3s_infinite]'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5 md:w-12 md:h-12">
                  <path stroke-linecap="round" stroke-linejoin="round" d="m9 12.75 3 3m0 0 3-3m-3 3v-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
              </div>
            </div>
          </RevealOnScroll>
          <RevealOnScroll extraClasses={'delay-700 col-start-4 md:row-start-4'}>
            <img src='./cloud.png' alt='' className='maxh20'></img>
          </RevealOnScroll>
      </div>
      <div className='flex flex-col h-svh justify-center items-center snap-start snap-always'>
        <div className='flex flex-col h-full italic items-center px-1 space-y-4 justify-around max-h-[35rem] md:max-h-[50rem] md:text-2xl'>
          <RevealOnScroll extraClasses={'delay-100'}>
            <p className='text-3xl md:text-6xl md:pb-5 oooh-baby-regular'>Valde skal døbes</p>
          </RevealOnScroll>
          <RevealOnScroll extraClasses={'delay-200'}>
            <div className='space-y-1 md:space-y-2'>
              <p className='font-serif'>og derfor vil vi gerne se dig / jer til barnedåb</p>
              <p className='font-serif'>i Vor Frue Kirke</p>
              <p className='font-serif'>Vor Frue Pl. 1, Aalborg</p>
            </div>
          </RevealOnScroll>
          <RevealOnScroll extraClasses={'delay-300'}>
            <p className='font-serif font-semibold'>Lørdag den 16. marts kl 10:30</p>
          </RevealOnScroll>
          <RevealOnScroll extraClasses={'delay-500'}>
            <div className='space-y-1 md:space-y-2'>
              <p className='font-serif'>Efterfølgende inviterer vi på frokost på</p>
              <p className='font-serif'>Restaurant SMAEK, kl 11:30</p>
              <p className='font-serif'>Ved Stranden 4, Aalborg</p>
            </div>
          </RevealOnScroll>
          <RevealOnScroll extraClasses={'delay-700'}>
            <p className='font-serif'>Vi håber på nogle hyggelige timer sammen</p>
          </RevealOnScroll>
          <RevealOnScroll extraClasses={'delay-700'}>
            <a className='font-serif border-b border-black' href='https://onskeskyen.dk/s/1ATc6' target="_blank">Klik her for Ønskeseddel</a>
          </RevealOnScroll>
          <RevealOnScroll extraClasses={'delay-700'}>
            <div className='space-y-2 md:space-y-4'>
              <p className='font-serif'>Kærlig hilsen</p>
              <p className='text-2xl md:text-4xl oooh-baby-regular'>Cecilie, Thomas & Valde</p>
            </div>
          </RevealOnScroll>
          <RevealOnScroll extraClasses={'flex items-center justify-center delay-1000 col-span-2'}>
            <div className='flex items-center justify-center space-x-2'>
              <p className='font-serif not-italic'>Rul ned for tilmelding</p>
              <div className='animate-[upl_3s_infinite]'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5 md:w-12 md:h-12">
                  <path stroke-linecap="round" stroke-linejoin="round" d="m9 12.75 3 3m0 0 3-3m-3 3v-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
              </div>
            </div>
          </RevealOnScroll>
        </div>
      </div>
      <div className='flex flex-col h-svh items-center snap-start snap-always space-y-4'>
        {selectedParticipant === undefined 
          ? <ParticipanList participants={participants} selectGroup={setSelectedParticipants}></ParticipanList>
          : <Invitation selectedParticipant={participants.find(p => p.id === selectedParticipant)} deselect={() => setSelectedParticipants(undefined)}></Invitation>
        }
      </div>
    </div>
  );
}

export default App;
