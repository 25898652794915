import { useEffect, useMemo, useState } from "react";

export const Invitation = (props) => {
  const [participants, setParticipants] = useState();

  useEffect(() => {
    if (props.selectedParticipant.participants !== participants) {
      setParticipants(props.selectedParticipant.participants);
    }
  }, [setParticipants, props.selectedParticipant]);

  // useEffect(() => {
  //   if (
  //     participants != null &&
  //     participants !== props.selectedParticipant.participants
  //   ) {
  //     props.updateParticipants({
  //       ...props.selectedParticipant,
  //       participants,
  //     });
  //   }
  // }, [props.updateParticipants, participants]);

  // const updateParticipants = useMemo(
  //   () => (p) => {
  //     if (p > 8) {
  //       setParticipants(8);
  //     } else if (p < 0) {
  //       setParticipants(0);
  //     } else if (p === "") {
  //       setParticipants(null);
  //     } else if (p !== "") {
  //       setParticipants(p);
  //     }
  //   },
  //   [setParticipants]
  // );

  // const increase = useMemo(
  //   () => () => {
  //     updateParticipants(participants + 1);
  //   },
  //   [updateParticipants, participants]
  // );

  // const decrease = useMemo(
  //   () => () => {
  //     updateParticipants(participants - 1);
  //   },
  //   [updateParticipants, participants]
  // );

  return (
    <div className="flex flex-col h-full justify-center">
      <div className="flex flex-col justify-around font-serif italic md:text-3xl h-full max-h-[30rem]">
        <p className="md:text-5xl text-3xl oooh-baby-regular">Hej {props.selectedParticipant.groupName}</p>

        <div className="space-y-2">
          <p>I har tidligere oplyst at i kommer {participants}.</p>
          <p>Vi er over S.U. dato, men skulle i komme flere eller færre end i har tilmeldt,</p>
          <p>så skriv gerne til</p>
          <p>Cecilie: 29 92 24 94 eller Thomas: 20 67 99 71.</p>
        </div>

        <div className="flex flex-col space-y-2 items-center cursor-pointer" onClick={props.deselect}>
          <p>Fik du trykket på det forkerte navn?</p>
          <p className="border-b border-black font-semibold w-fit">Tryk her for gå tilbage!</p>
        </div>
      </div>
    </div>
  );
};
